import React, {useEffect, useState} from "react";
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import axisLogo from '../../../images/logos/logo-axis.svg';
import cueLogo from '../../../images/logos/logo-cue.svg';
import solaLogo from '../../../images/logos/logo-sola.svg';
import rangeLogo from '../../../images/logos/logo-range.svg';
import Modal from '../../../components/modals/modal';
import CustomLocalizedLink from "../../../components/locales/custom-localized-link";
import logoCurve from "../../../images/locks/product-pages/curve/logo-gray-curve.png";
import logoAspire from "../../../images/locks/product-pages/aspire/logo-gray-aspire.png";
import logoVersa from "../../../images/locks/product-pages/versa/logo-gray-versa.png";
import logoOrbit from "../../../images/locks/product-pages/orbit/logo-gray-orbit.png";

const IndexPage = () => {

  const {t} = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showCurveVideoModal, setShowCurveVideoModal] = useState(false);

  function handleShowModal() {
    const bodyElm = document.body;
    setShowModal(prevShowModal => !prevShowModal);
    bodyElm.classList.toggle('stop-body-scroll');
  }

  function handleShowCurveVideoModal() {
    const bodyElm = document.body;
    const digiVid = document.getElementsByTagName('video')[0];
    digiVid.pause();
    digiVid.pause();
    digiVid.currentTime = 0;
    digiVid.parentElement.classList.remove('vjs-has-started');
    setShowCurveVideoModal(prevShowCurveVideoModal => !prevShowCurveVideoModal);
    bodyElm.classList.toggle('stop-body-scroll');
  }

  function handleScrollTo(elmID) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: document.getElementById(elmID).offsetTop - 150
    });
  }

  useEffect(() => {
    if (document.location.hash === '#gotoCurve') {
      handleScrollTo("curve");
    }
    if (document.location.hash === '#gotoAspire') {
      handleScrollTo("aspire");
    }
    if (document.location.hash === '#gotoVersa') {
      handleScrollTo("versa");
    }
    if (document.location.hash === '#gotoOrbit') {
      handleScrollTo("orbit");
    }
  }, []);

  return (
      <Layout>
        <Seo
            title={t('products_electronic_locks')}
            description={t('des_products_electronic_locks')}
        />
        <div className="products electronic-locks-page">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/electronic-locks/hero-electronic.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Electronic Locks that require no wiring"
            />
            <h1>
              <Trans>
                electronic_locks
              </Trans>
            </h1>
          </section>
          <section className={'smart-locks-overview'}>
            <div className="container">
              <h2 className={'headline'}>
                <Trans>
                  el_headline
                </Trans>
              </h2>
              <p>
                <Trans>
                  el_headline_copy
                </Trans>
              </p>
              <div className="center">
                <ul>
                  <li><Trans>el_headline_list_1</Trans></li>
                  <li><Trans>el_headline_list_2</Trans></li>
                  <li><Trans>el_headline_list_3</Trans></li>
                  <li><span onClick={handleShowModal}><Trans>el_headline_list_4</Trans></span></li>
                  <li><Trans>el_headline_list_5</Trans></li>
                  <li><Trans>el_headline_list_6</Trans></li>
                  <li><Trans>el_headline_list_7</Trans></li>
                  <li><Trans>el_headline_list_8</Trans></li>
                  <li><Trans>el_headline_list_9</Trans></li>
                </ul>
              </div>
            </div>
          </section>
          <div id={'curve'} className="section-delimeter container"/>
          <section className="smart-curve">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <img className={'logo'} src={logoCurve} alt="Curve Logo" width={'183'} height={'52'}/>
                  <h2>
                    <Trans>el_curve_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>el_curve_list1</Trans></li>
                    <li><Trans>el_curve_list2</Trans></li>
                    <li><Trans>el_curve_list5</Trans></li>
                    <li><Trans>el_curve_list3</Trans></li>
                    <li><Trans>el_curve_list4</Trans></li>
                    <li>
                      <Trans>el_versa_list7_pre</Trans>
                      <Link to={'/products/smart-locks/#gotoCurve'}>{t('smart_lock')}</Link>
                      <Trans>el_versa_list7_post</Trans>
                    </li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto="/products/smart-locks/curve/"
                                         cls="btn btn--orange"
                                         label={t('see_specs')}
                    />
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/electronic-locks/elec-curve-four.png"
                      loading={'lazy'}
                      width={512}
                      height={433}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Curve locks"
                  />
                </div>
              </div>
            </div>
          </section>
          <div id={'aspire'} className="section-delimeter container"/>
          <section className="smart-aspire">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/locks/DL22-aspire-600x497.png"
                      loading={'lazy'}
                      width={624}
                      height={461}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Aspire Electronic Lock"
                  />
                </div>
                <div className="grid-two-col-item-single">
                  <img className={'logo'} src={logoAspire} alt="Aspire Logo" width={'191'} height={'65'}/>
                  <h2>
                    <Trans>el_aspire_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>el_aspire_list1</Trans></li>
                    <li><Trans>el_aspire_list2</Trans></li>
                    <li><Trans>el_aspire_list3</Trans></li>
                    <li><Trans>el_aspire_list4</Trans></li>
                    <li><Trans>el_aspire_list5</Trans></li>
                    <li>
                      <Trans>el_aspire_list6_pre</Trans>
                      <Link to={'/products/smart-locks/#gotoAspire'}>{t('smart_lock')}</Link>
                      <Trans>el_aspire_list6_post</Trans>
                    </li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto="/products/smart-locks/aspire/"
                                         cls="btn btn--orange"
                                         label={t('see_specs')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id={'versa'} className="section-delimeter container"/>
          <section className="smart-versa">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <img className={'logo'} src={logoVersa} alt="Versa Logo" width={'162'} height={'50'}/>
                  <h2>
                    <Trans>el_versa_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>el_versa_list1</Trans></li>
                    <li><Trans>el_versa_list2</Trans></li>
                    <li><Trans>el_versa_list3</Trans></li>
                    <li><Trans>el_versa_list4</Trans></li>
                    <li><Trans>el_versa_list5</Trans></li>
                    <li><Trans>el_versa_list6</Trans></li>
                    <li>
                      <Trans>el_versa_list7_pre</Trans>
                      <Link to={'/products/smart-locks/#gotoVersa'}>{t('smart_lock')}</Link>
                      <Trans>el_versa_list7_post</Trans>
                    </li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto="/products/smart-locks/versa/"
                                         cls="btn btn--orange"
                                         label={t('see_specs')}
                    />
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/electronic-locks/elec-versa-four.png"
                      loading={'lazy'}
                      width={624}
                      height={461}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Versa Electronic Lock"
                  />
                </div>
              </div>
            </div>
          </section>
          <div id={'orbit'} className="section-delimeter container"/>
          <section className="smart-orbit">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single">
                  <StaticImage
                      src="../../../images/electronic-locks/elec-orbit-four.png"
                      loading={'lazy'}
                      width={624}
                      height={461}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Orbit Electronic Lock"
                  />
                </div>
                <div className="grid-two-col-item-single">
                  <img className={'logo'} src={logoOrbit} alt="Orbit Logo" width={'163'} height={'53'}/>
                  <h2>
                    <Trans>el_orbit_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>el_orbit_list1</Trans></li>
                    <li><Trans>el_orbit_list2</Trans></li>
                    <li><Trans>el_orbit_list3</Trans></li>
                    <li><Trans>el_orbit_list4</Trans></li>
                    <li><Trans>el_orbit_list5</Trans></li>
                    <li><Trans>el_orbit_list6</Trans></li>
                    <li>
                      <Trans>el_orbit_list7_pre</Trans>
                      <Link to={'/products/smart-locks/#gotoOrbit'}>{t('smart_lock')}</Link>
                      <Trans>el_orbit_list7_post</Trans>
                    </li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto="/products/smart-locks/orbit/"
                                         cls="btn btn--orange"
                                         label={t('see_specs')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container"/>
          <section className="legacy-locks-overview">
            <div className="container">
              <h2>
                <Trans>
                  legacy_headline
                </Trans>
              </h2>
              <p>
                <Trans>
                  legacy_headline_copy
                </Trans>
              </p>
              <div className="center">
                <ul>
                  <li><span onClick={handleShowModal}><Trans>legacy_headline_list_1</Trans></span></li>
                  <li><Trans>legacy_headline_list_2</Trans></li>
                  <li><Trans>legacy_headline_list_3</Trans></li>
                  <li><Trans>legacy_headline_list_4</Trans></li>
                  <li><Trans>legacy_headline_list_5</Trans></li>
                </ul>
              </div>
            </div>
          </section>
          <div className="section-delimeter container"/>
          <section className="legacy-axis">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <img src={axisLogo} width={'225'} height={'88'} alt="Axis Logo"/>
                  <h2>
                    <Trans>el_axis_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>el_axis_list1</Trans></li>
                    <li><Trans>el_axis_list2</Trans></li>
                    <li><Trans>el_axis_list3</Trans></li>
                    <li><Trans>el_axis_list4</Trans></li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink
                        goto="/products/electronic-locks/axis/"
                        cls="btn btn--orange"
                        label={t('see_specs')}
                    />
                  </div>
                </div>
                <div className="grid-two-col-item-single">

                    <StaticImage
                        src="../../../images/electronic-locks/elec-axis-four.png"
                        loading={'lazy'}
                        width={624}
                        height={461}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Axis Electronic Lock"
                    />

                </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container"/>
          <section className="legacy-cue">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single">
                    <StaticImage
                        src="../../../images/electronic-locks/elec-cue-four.png"
                        loading={'lazy'}
                        width={624}
                        height={461}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Cue Electronic Lock"
                    />
                </div>
                <div className="grid-two-col-item-single">
                  <img src={cueLogo} width={'225'} height={'88'} alt="Cue Logo"/>
                  <h2>
                    <Trans>el_cue_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>el_cue_list1</Trans></li>
                    <li><Trans>el_cue_list2</Trans></li>
                    <li><Trans>el_cue_list3</Trans></li>
                    <li><Trans>el_cue_list4</Trans></li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink
                        goto="/products/electronic-locks/cue/"
                        cls="btn btn--orange"
                        label={t('see_specs')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container"/>
          <section className="legacy-sola">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <img src={solaLogo} width={'225'} height={'88'} alt="Sola Logo"/>
                  <h2>
                    <Trans>el_sola_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>el_sola_list1</Trans></li>
                    <li><Trans>el_sola_list2</Trans></li>
                    <li><Trans>el_sola_list3</Trans></li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink
                        goto="/products/electronic-locks/sola/"
                        cls="btn btn--orange"
                        label={t('see_specs')}
                    />
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                    <StaticImage
                        src="../../../images/electronic-locks/elec-sola-four.png"
                        loading={'lazy'}
                        width={624}
                        height={461}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Sola Electronic Lock"
                    />
                </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container"/>
          <section className="legacy-range">
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single">
                    <StaticImage
                        src="../../../images/electronic-locks/elec-range-four.png"
                        loading={'lazy'}
                        width={624}
                        height={461}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Range Electronic Lock"
                    />
                </div>
                <div className="grid-two-col-item-single">
                  <img src={rangeLogo} width={'225'} height={'88'} alt="Range Logo"/>
                  <h2>
                    <Trans>el_range_headline</Trans>
                  </h2>
                  <ul>
                    <li><Trans>el_range_list1</Trans></li>
                    <li><Trans>el_range_list2</Trans></li>
                    <li><Trans>el_range_list3</Trans></li>
                    <li><Trans>el_range_list4</Trans></li>
                    <li><Trans>el_range_list5</Trans></li>
                  </ul>
                  <div className="sl-buttons">
                    <CustomLocalizedLink goto="/products/electronic-locks/range/"
                                         cls="btn btn--orange"
                                         label={t('see_specs')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Modal show={showModal} handleClose={handleShowModal} cls={'elock-modal'} background={'light'}>
            <div className="modal-content">
              <p dangerouslySetInnerHTML={{__html: t('sl_modal_shared')}}/>
              <p dangerouslySetInnerHTML={{__html: t('sl_modal_assigned')}}/>
            </div>
          </Modal>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
